import {bind} from './lib/bind'
import {debounce} from './lib/debounce'

const performStyleUpdates = function(e) {
  const form = e.target.closest('form');
  const samplesUrl = form.dataset.samplesUrl

  Array.from(document.querySelectorAll('.js-font-sample')).forEach((sample) => {
    sample.innerHTML += "<div class=\"spinner-fill spinner-fill-white\"><div class=\"spinner-center\"><div class=\"spinner\"></div></div></div>"
  });
  
  /* Disabling ESLint Promises as this is Admin Only */
  /* eslint-disable promise/always-return */
  /* eslint-disable promise/catch-or-return */
  fetch(samplesUrl, {
    body: new FormData(form),
    method: "post"
  })
  .then(function(response) {
    return response.json();
  })
  .then(function(data) {
    if (!data) {
      throw new Error("Empty data")
    }

    for(var key in data.samples) {
      const el = document.querySelector(`[data-font-sample='${key}']`)
      const html = data['samples'][key]
      el.outerHTML = html
    }
  })
  /* eslint-enable promise/always-return */
  /* eslint-enable promise/catch-or-return */
};

const debouncedPerformStyleUpdates = debounce(performStyleUpdates, 700);

bind('[data-samples-url] .js-font-adjustment', 'keydown', function(e) {
  debouncedPerformStyleUpdates(e);
});
