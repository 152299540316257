/* Disabling ESLint Promises as the updatePendingFeeds() constant is currently
 * written to rerun in continuously after 1 second if the a response is not received.
 */
/* eslint-disable promise/always-return */
/* eslint-disable promise/catch-or-return */
const updatePendingFeeds = () => {
  const elements = Array.from(document.querySelectorAll('[data-feed-status="pending"]'))
  elements.forEach((element) => {
    const url = element.dataset.feedUrl
    setTimeout(() => {
      fetch(url)
      .then((response) => {
        return response.json()
      })
      .then((data) => { 
        element.outerHTML = data.html
        updatePendingFeeds()
      })
    }, 1000)
  })
}
/* eslint-enable promise/catch-or-return */
/* eslint-enable promise/always-return */

updatePendingFeeds()
